import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";

function landingPage() {
  return (
    <Layout>
      <SEO
        title="Landing"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <Hero />

      <div className="flex flex-col md:flex-row items-center w-full">
        <p className="text-white text-2xl">Where are you?</p>
      </div>
    </Layout>
  );
}

export default landingPage;
